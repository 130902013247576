
import Vue from 'vue';

import { AxiosError, AxiosResponse } from 'axios';
import { IUser } from '../interfaces/user';

export interface SuccessResponse{
    status: number
}
export interface ErrorResponse{
    status: number
    message: string
    errors?: Array<any> | any
}

const component = Vue.extend({
    computed: {
        currentUser(): IUser {
            return this.$store.getters.user;
        },
    },
    data() {
        return {
            isLoading: false as Boolean,
            errors: false as any,
        };
    },
    props: {

        error: {
            type: [Object, Array, Boolean, String],
            default() {
                return false;
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },

    },
    mounted() {
    },
    watch: {
        error(value: any) {
            this.errors = value;
        },
        loading(value: any) {
            this.isLoading = value;
        },

    },
    methods: {
        get<T = any>(url: string, config?: any): Promise<AxiosResponse<T>> {
            this.errors = false;
            this.isLoading = true;
            return new Promise<AxiosResponse<T>>((resolve, reject) => {
                this.$http.get<T>(url, config)
                    .catch((err) => {
                        this.handleError(err);
                        reject(err);
                    })
                    .finally(() => {
                        this.handleFinally();
                    })
                    .then((response) => {
                        if (response) resolve(response);
                    });
            });
        },
        post<T = any>(url: string, data?: any, config: any = null): Promise<AxiosResponse<T>> {
            this.errors = false;
            this.isLoading = true;
            return new Promise<AxiosResponse<T>>((resolve, reject) => {
                this.$http.post<T>(url, data, config)
                    .catch((err) => {
                        this.handleError(err);
                        reject(err);
                    })
                    .finally(() => {
                        this.handleFinally();
                    })
                    .then((response) => {
                        if (response) resolve(response);
                    });
            });
        },
        put<T = any>(url: string, data?: any, config: any = null): Promise<AxiosResponse<T>> {
            this.errors = false;
            this.isLoading = true;
            return new Promise<AxiosResponse<T>>((resolve, reject) => {
                this.$http.put<T>(url, data, config)
                    .catch((err) => {
                        this.handleError(err);
                        reject(err);
                    })
                    .finally(() => {
                        this.handleFinally();
                    })
                    .then((response) => {
                        if (response) resolve(response);
                    });
            });
        },
        delete<T = any>(url: string, config?: any): Promise<AxiosResponse<T>> {
            this.errors = false;
            this.isLoading = true;
            return new Promise<AxiosResponse<T>>((resolve) => {
                this.$http.delete<T>(url, config)
                    .catch(this.handleError)
                    .finally(this.handleFinally)
                    .then((response) => {
                        if (response) resolve(response);
                    });
            });
        },
        findVeeObservers(obj: any) {
            let found = obj.$_veeObserver;

            if (!found && obj.$children) {
                for (let i = 0; i < obj.$children.length; i += 1) {
                    found = this.findVeeObservers(obj.$children[i]);
                    if (found) break;
                }
            }

            return found;
        },
        handleError(err:AxiosError) {
            if (err.response) {
                const response: ErrorResponse = err.response.data as ErrorResponse;

                if (response.errors && this.$refs.form) {
                    (this.$refs.form as any).setErrors(response.errors);
                }

                this.$emit('error', err.response.data);
            } else {
                this.$emit('error', { message: err.message });
                this.isLoading = false;
            }
        },
        handleFinally() {
            this.isLoading = false;
        },
        confirm(message: string = '') {
            return new Promise((resolve) => {
                this.$buefy.dialog.confirm({
                    message: message || 'Are you sure ?',
                    onConfirm: resolve,
                });
            });
        },
    },
});

export default component;
