






























import { BNoticeConfig } from 'buefy/types/components';
import * as Sentry from '@sentry/vue';
import Base from '@/mixins/Base.vue';
import Main from '@/pages/Main.vue';

const component = Base.extend({
    name: 'App',
    components: {
        Main,
    },
    data() {
        return {
            notification: {} as BNoticeConfig,
        };
    },
    mounted() {
        Sentry.captureMessage('Hello ?');
    },
    computed: {
        permissions() : Array<string> {
            return this.$store.getters.permissions;
        },
    },
    methods: {
        notify(type: string, event: any): void {
            if (typeof event === 'object') this.notification = event;

            if (event.body && event.body.message) {
                this.notification.message = event.body.message;
            } else if (event.message) {
                this.notification.message = event.message;
            } else {
                this.notification.message = 'An error occured';
            }
            this.notification.type = `is-${type}`;
            this.notification.duration = event.duration || 2000;
            this.notification.position = event.position || 'is-top';
            this.notification.queue = false;

            this.$buefy.toast.open(this.notification);
        },
    },
});

export default component;
