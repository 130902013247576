






























































import { AxiosResponse } from 'axios';
import Base from '@/mixins/Base.vue';
import { IUserResponse } from '@/interfaces/user';
import { ILanguagesResponse } from '@/interfaces/language';
import Sidebar from './navigation/Sidebar.vue';
import Topbar from './navigation/Topbar.vue';
import SearchModal from './search/modal.vue';

const component = Base.extend({
    components: {
        Sidebar,
        Topbar,
        SearchModal,
    },
    data() {
        return {
            routerKey: Math.random().toString(36).substring(7) as String,
            isSidebarCollapsed: false as Boolean,
            isSearchOpened: false as Boolean,
        };
    },
    mounted(): void {
        this.getLanguages();
    },
    methods: {
        getLanguages() {
            this.get<ILanguagesResponse>('languages').then(({ data }) => {
                this.$store.dispatch('getLanguages', data.data);
                this.getUser();
            });
        },
        logout(): void {
            window.location.href = `${process.env.VUE_APP_KEYCLOAK_BASE_URL}/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`;
        },
        changeLocale(locale: string): void {
            this.$i18n.locale = locale;
        },
        changeClient(client: { id: any; name: any; }): void {
            if (client && client.id) {
                this.currentUser.current_client_id = client.id;
                this.updateUser().then(() => {
                    this.$emit('success', { message: `You are now using ${client.name} with role ${this.currentUser.role ? this.currentUser.role.name : ''}`, duration: 2000 });
                })
                    .catch(() => this.$emit('loaded'));
            }
        },
        resetClient(): void {
            this.currentUser.current_client_id = null;
            this.updateUser();
        },
        updateUser(): Promise<AxiosResponse<any>> {
            this.$emit('loading');

            const promise: Promise<AxiosResponse<any>> = this.put<IUserResponse>('auth/me', {
                ...this.currentUser,
                generic_role: this.currentUser.generic_role ? this.currentUser.generic_role.name : null,
            });

            promise.then(({ data }) => {
                this.$store.dispatch('getUser', data.data).then(() => {
                    this.routerKey = Math.random().toString(36).substring(7);
                });
            });
            promise.catch(() => this.$emit('loaded'));
            promise.finally(() => this.$emit('loaded'));

            return promise;
        },
        getUser(): void {
            this.$emit('loading');
            this.get<IUserResponse>('auth/me')
                .then(({ data }) => {
                    this.$store.dispatch('getUser', data.data);
                })
                .catch(() => this.$emit('loaded'))
                .finally(() => this.$emit('loaded'));
        },
        openSearch(): void {
            this.isSearchOpened = true;
        },
        closeSearch(route: any): void {
            this.isSearchOpened = false;

            if (route) {
                this.$router.push(route);
                this.routerKey = Math.random().toString(36).substring(7);
            }
        },

    },

});

export default component;
