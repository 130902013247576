







































































































































































































































import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },
        required: {
            type: Boolean,
            default: false,
        },
        withAutocomplete: {
            type: Boolean,
            default: false,
        },
        withCurrentPosition: {
            type: Boolean,
            default: false,
        },
        withMap: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: true,
        },
        labelPosition: {
            type: String,
            default: null,
        },

        latParam: {
            type: String,
            default: 'lat',
        },
        lngParam: {
            type: String,
            default: 'lng',
        },
        streetParam: {
            type: String,
            default: 'street',
        },
        numberParam: {
            type: String,
            default: 'number',
        },
        boxParam: {
            type: String,
            default: 'box',
        },
        postalCodeParam: {
            type: String,
            default: 'postal_code',
        },
        cityParam: {
            type: String,
            default: 'city',
        },
        countryParam: {
            type: String,
            default: 'country',
        },
        regionParam: {
            type: String,
            default: 'region',
        },
        error: {
            type: [Object, Array, Boolean, String],
            default: false,
        },
    },
    data() {
        return {
            lat: 0,
            lng: 0,
            isLoading: false,
            newValue: this.value || {},
            map: undefined as any,
            marker: undefined as any,
            isMapExpanded: false,
            errors: this.error,
        };
    },
    mounted() {
        setTimeout(this.loadMap, 100);

        this.lat = this.newValue[this.latParam] ? parseFloat(this.newValue[this.latParam]) : 0;
        this.lng = this.newValue[this.lngParam] ? parseFloat(this.newValue[this.lngParam]) : 0;
    },

    methods: {
        parseAddressComponents(place: any): void {
            if (place.address_components) {
                place.address_components.forEach((comp : any) => {
                    if (comp.types[0] === 'street_number') this.$set(this.newValue, this.numberParam, comp.long_name);
                    if (comp.types[0] === 'postal_code') this.$set(this.newValue, this.postalCodeParam, comp.long_name);
                    if (comp.types[0] === 'country') {
                        switch (comp.long_name.toLowerCase()) {
                        case 'belgium':
                            this.$set(this.newValue, this.countryParam, { id: 1, name: 'Belgium' });
                            this.$set(this.newValue, `${this.countryParam}_id`, 1);
                            break;
                        case 'france':
                            this.$set(this.newValue, this.countryParam, { id: 2, name: 'France' });
                            this.$set(this.newValue, `${this.countryParam}_id`, 2);
                            break;
                        case 'netherlands':
                            this.$set(this.newValue, this.countryParam, { id: 3, name: 'Netherlands' });
                            this.$set(this.newValue, `${this.countryParam}_id`, 3);
                            break;
                        default:
                            this.$set(this.newValue, this.countryParam, undefined);
                            break;
                        }
                    }

                    if (comp.types[0] === 'administrative_area_level_1') {
                        switch (comp.long_name) {
                        case 'Vlaams Gewest':
                            this.$set(this.newValue, this.regionParam, { id: 1, name: 'Flanders' });
                            this.$set(this.newValue, `${this.regionParam}_id`, 1);
                            break;
                        case 'Bruxelles':
                            this.$set(this.newValue, this.regionParam, { id: 2, name: 'Brussels' });
                            this.$set(this.newValue, `${this.regionParam}_id`, 2);
                            break;
                        case 'Brussels':
                            this.$set(this.newValue, this.regionParam, { id: 2, name: 'Brussels' });
                            this.$set(this.newValue, `${this.regionParam}_id`, 2);
                            break;
                        case 'Région Wallonne':
                            this.$set(this.newValue, this.regionParam, { id: 3, name: 'Wallonia' });
                            this.$set(this.newValue, `${this.regionParam}_id`, 3);
                            break;
                        default:
                            break;
                        }
                    }
                    if (comp.types[0] === 'route') {
                        (this.$refs as any).street.$el.firstChild.value = comp.long_name;
                        this.$set(this.newValue, this.streetParam, comp.long_name);
                    }
                    if (comp.types[0] === 'locality') this.$set(this.newValue, this.cityParam, comp.long_name);
                });
            }
            if (place.geometry) {
                if (this.map) {
                    this.map.setCenter(place.geometry.location);
                    this.map.setZoom(12);

                    this.marker.setPosition(place.geometry.location);
                }

                this.$set(this.newValue, this.latParam, place.geometry.location.lat());
                this.$set(this.newValue, this.lngParam, place.geometry.location.lng());
            }
        },
        loadMap() : void{
            if ((window as any).google && (this.withMap || this.withAutocomplete || this.withCurrentPosition)) {
                if (this.withMap) {
                    this.map = new (window as any).google.maps.Map(this.$refs.map, {
                        center: { lat: this.lat, lng: this.lng },
                        zoom: 8,
                        disableDefaultUI: true,
                    });

                    this.marker = new (window as any).google.maps.Marker({
                        map: this.map,
                        position: new (window as any).google.maps.LatLng(this.lat, this.lng),
                    });
                }

                if (this.withAutocomplete) {
                    if ((this.$refs as any).street.$el) {
                        const el = (this.$refs as any).street.$el.firstChild;
                        const autocomplete = new (window as any).google.maps.places.Autocomplete(el, {
                            fields: ['address_components', 'geometry'],
                            componentRestrictions: { country: ['be', 'fr', 'nl'] },
                        });

                        autocomplete.addListener('place_changed', () => {
                            const place = autocomplete.getPlace();
                            this.parseAddressComponents(place);
                        });
                    }
                }
            }
        },
        updateLocation() : void {
            const geocoder = new (window as any).google.maps.Geocoder();

            const address = `${this.newValue[this.streetParam]
            } ${this.newValue[this.numberParam]
            } ${this.newValue[this.postalCodeParam]
            } ${this.newValue[this.cityParam]}
            } ${this.newValue[this.countryParam]}`;

            geocoder.geocode({
                address,
            }, (results: any, status: string) => {
                if (status === 'OK') {
                    this.parseAddressComponents(results[0]);
                    this.$emit('input', this.newValue);
                } else {
                    this.$emit('error', {
                        message: 'Cannot find address',
                    });
                }
            });
        },
        getCurrentLocation() : void{
            if (!('geolocation' in navigator)) {
                this.errors.message = 'Geolocation is not available now.';
                return;
            }

            navigator.geolocation.getCurrentPosition((pos) => {
                this.isLoading = false;
                const coordinates = { lat: pos.coords.latitude, lng: pos.coords.longitude };

                const geocoder = new (window as any).google.maps.Geocoder();

                geocoder.geocode({ location: coordinates }, (results: any[], status: string) => {
                    if (status === 'OK') {
                        this.parseAddressComponents(results[0]);
                    }
                });
            }, (err) => {
                this.errors.message = err.message;
                this.isLoading = false;
            });
        },
    },
    watch: {
        error(value) {
            this.errors = value;
        },
        value(value) {
            this.newValue = value || {};
        },
    },
});

export default component;
