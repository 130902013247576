

































































import { debounce } from 'ts-debounce';

import Base from '@/mixins/Base.vue';
import { IPermission, IPermissionsResponse } from '@/interfaces/permission';

const component = Base.extend({
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Object, Array],
            default: null,
        },
        roleId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            name: '',
            page: 1,
            totalPages: 1,
            isLoading: false,
            newValue: null,
            data: [] as Array<IPermission>,
        };
    },
    mounted() {
        if (!this.multiple) this.newValue = this.value ? this.value.name : '';
        else this.newValue = this.value ? this.value : [];

        if (!this.value) this.$emit('input', this.multiple ? [] : null);
    },
    methods: {
        reset(): void {
            this.page = 1;
            this.totalPages = 1;
            this.name = '';
            this.data = [];
            this.fetchData();
        },
        onBeforeAdding(value: any): boolean {
            if (this.multiple && this.newValue && (this.newValue as any).findIndex((v: any) => v.id === value.id) !== -1) {
                return false;
            }
            return true;
        },
        onFocus(): void {
            this.reset();
        },
        onSelect(obj: IPermission): void {
            if (!obj) this.reset();
            if (!this.multiple) this.$emit('input', obj || null);
        },
        onInput(obj: IPermission): void {
            if (this.multiple) this.$emit('input', obj || []);
        },
        // eslint-disable-next-line func-names
        onTyping: debounce(function (this: any, name: string = ''): void {
            this.fetchData(name);
        }, 500),

        fetchData(name: string = '') {
            const self = this;

            if (self.name !== name) {
                self.name = name;
                self.page = 1;
                self.totalPages = 1;
                self.data = [];
            }

            if (this.page > this.totalPages) {
                return;
            }

            this.get<IPermissionsResponse>('permissions', {
                params: {
                    name: this.name,
                    page: this.page,
                    role_id: this.roleId,
                },
            }).then(({ data }) => {
                this.data.push(...data.data);
                this.page += 1;

                this.totalPages = data.meta.last_page;
            });
        },
        // eslint-disable-next-line func-names
        fetchMoreData: debounce(function (this: any, name: string): void {
            this.fetchData(name);
        }, 300),

    },
    watch: {
        value(value): void {
            if (this.multiple) this.newValue = value || [];
            else this.newValue = value ? value.name : '';
        },
        clientId(): void {
            this.reset();
        },
    },
});

export default component;
