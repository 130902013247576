

















































import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isScrollable: true,
            newValue: this.value ? this.value : null,
            colors: [
                'rgb(231,106,94)',
                'rgb(85,142,241)',
                'rgb(236,166,93)',
                'rgb(170,117,207)',
                'rgb(125,204,113)',
            ],
        };
    },
    mounted() {
        this.newValue = this.value || null;
    },
    methods: {
        onSelect(obj: any): void {
            this.newValue = obj;
            this.$emit('input', obj);
        },
    },
});

export default component;
