









































































import { debounce } from 'ts-debounce';
import { AxiosResponse } from 'axios';
import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            placeholder: 'Search for users, services, jobs, objects,...',
            history: [] as Array<any>,
            data: [] as Array<any>,
            name: '' as String,
            default_data: [
                {
                    type: 'List...',
                    items: [
                        { type: 'actions', name: 'List services', route: 'services.list' },
                        { type: 'actions', name: 'List users', route: 'users.list' },
                        { type: 'actions', name: 'List jobs', route: 'jobs.list' },
                        { type: 'actions', name: 'List clients', route: 'clients.list' },
                        { type: 'actions', name: 'List objects', route: 'objects.list' },
                        { type: 'actions', name: 'List roles', route: 'roles.list' },
                        { type: 'actions', name: 'List Project', route: 'projects.list' },
                        { type: 'actions', name: 'List Category', route: 'categories.list' },
                    ],
                },
                {
                    type: 'Create...',
                    items: [
                        { type: 'actions', name: 'Create service', route: 'services.create' },
                        { type: 'actions', name: 'Create User', route: 'users.create' },
                        { type: 'actions', name: 'Create Job', route: 'jobs.create' },
                        { type: 'actions', name: 'Create Companie', route: 'clients.create' },
                        { type: 'actions', name: 'Create Object', route: 'objects.create' },
                        { type: 'actions', name: 'Create Role', route: 'roles.create' },
                        { type: 'actions', name: 'Create Project', route: 'projects.create' },
                        { type: 'actions', name: 'Create Category', route: 'categories.create' },
                    ],
                },
            ],
        };
    },

    watch: {
        active(value): void {
            if (value) {
                this.name = '';
                this.data = [];

                if (this.history.length) {
                    this.data.push({
                        type: 'History',
                        items: this.history,
                    });
                }
                this.data = this.data.concat(this.default_data);

                setTimeout(() => {
                    const ref: HTMLInputElement = this.$refs.autocomplete as HTMLInputElement;

                    ref.focus();
                    // ref.focus(undefined);
                });
            }
        },
    },
    mounted() {
        // this.data = this.default_data;
        const history: string = localStorage.getItem('history') || '';
        if (history) {
            this.history = JSON.parse(history);
        }
    },
    methods: {
        filterObj(data: any) : Array<any> {
            const newData: Array<any> = [];

            data.forEach((element: any) => {
                const items = element.items.filter((item: any) => item.name.toLowerCase().indexOf(this.name.toLowerCase()) >= 0);
                if (items.length) {
                    newData.push({ type: element.type, items });
                }
            });
            return newData;
        },
        onSelect(option: { type: any; id: any; client_service: { client_id: any; }; route: any;name: string; }): void {
            let route = null;
            const params: any = {};
            if (option) {
                let routeName;
                switch (option.type.toLowerCase()) {
                case 'jobs':
                    routeName = 'jobs.edit';
                    params.id = option.id;
                    break;
                case 'users':
                    routeName = 'users.edit';
                    params.id = option.id;
                    break;
                case 'services':
                    routeName = 'services.edit';
                    params.id = option.id;
                    if (option.client_service) {
                        params.client_id = option.client_service.client_id;
                    }
                    break;
                case 'objects':
                    routeName = 'objects.edit';
                    params.id = option.id;
                    break;
                case 'clients':
                    routeName = 'clients.edit';
                    params.id = option.id;
                    break;
                case 'groups':
                    routeName = 'groups.edit';
                    params.id = option.id;
                    break;
                case 'pages':
                    routeName = option.route;
                    params.id = option.id;
                    break;
                case 'actions':
                    routeName = option.route;
                    params.id = option.id;
                    break;

                default: break;
                }

                route = { name: routeName, params };
            }

            this.$emit('close-search', route);

            if (this.history.findIndex((h) => h.name === option.name) === -1) {
                this.history.unshift(option);

                if (this.history.length > 5) {
                    this.history.pop();
                }
            }
            localStorage.setItem('history', JSON.stringify(this.history));
        },
        // eslint-disable-next-line func-names
        fetchData: debounce(function (this: any, lookup: string = ''): void {
            this.post('search', {
                lookup,
            }).then((response: AxiosResponse) => {
                this.data = [];

                if (response.data.total > 0) {
                    response.data.data.forEach((d: any) => {
                        if (d.items.length) {
                            d.items.forEach((i: any) => {
                                const item = i;
                                item.type = d.type;
                            });

                            this.data.push({
                                type: d.type,
                                items: d.items,
                            });
                        }
                    });
                }

                const filteredObject = (this.filterObj(this.default_data));
                this.data.push(...filteredObject);
            });
        }, 500),
    },
});

export default component;
