






















import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        behavior: {
            type: String,
            default: 'hide',
        },
        scope: {
            type: String,
            default: null,
        },
        tag: {
            type: String,
            default: 'router-link',
        },
        to: {
            type: Object,
            default: () => ({ name: 'dashboard' }),
        },

    },
    computed:
    {
        permissions() : Array<string> {
            return this.$store.getters.permissions;
        },
        hasAccess() : Boolean {
            const route = this.$router.getRoutes().find((r) => r.name === this.to.name);

            if (this.permissions && route && route.meta.permission) {
                return this.permissions.includes(route.meta.permission);
            }
            return false;
        },
    },
    mounted() {

    },

});

export default component;
