























import Base from '@/mixins/Base.vue';

const component = Base.extend({
    name: 'VueWebCam',
    props: {
        width: {
            type: [Number, String],
            default: '100%',
        },
        height: {
            type: [Number, String],
            default: 500,
        },
        scanner: {
            type: Boolean,
            default: true,
        },
        autoplay: {
            type: Boolean,
            default: true,
        },
        screenshotFormat: {
            type: String,
            default: 'image/jpeg',
        },
        selectFirstDevice: {
            type: Boolean,
            default: true,
        },

        playsinline: {
            type: Boolean,
            default: true,
        },
        resolution: {
            type: Object,
            default: null,
            validator: (value) => value.height && value.width,
        },
        checking: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            deviceId: undefined,
            isLoading: false,
            scannerTimer: undefined as any,
            barcodeDetector: undefined as any,
            source: undefined as any,
            canvas: undefined as any,
            camerasListEmitted: false,
            cameras: [] as Array<any>,
            ctx: undefined as any,
        };
    },
    watch: {
        deviceId(id): void {
            this.changeCamera(id);
        },
        checking(value): void {
            if (value) this.detectCode();
        },
    },
    mounted() {
        this.setupMedia();
    },
    beforeDestroy() {
        this.stop();
    },
    methods: {
        prepareScanner() : void{
            if (!(window as any).BarcodeDetector) return;

            this.barcodeDetector = new (window as any).BarcodeDetector({ formats: ['qr_code'] });

            this.detectCode();
        },
        detectCode() : void{
            // Start detecting codes on to the video element
            if (this.barcodeDetector && this.checking) {
                this.barcodeDetector.detect(this.$refs.video)
                    .then((codes: Array<any>) => {
                        if (codes && codes.length > 0) {
                            this.$emit('input', codes[0].rawValue);
                        }

                        this.scannerTimer = setTimeout(this.detectCode, 300);
                    })
                    .catch(() => {
                        clearTimeout(this.scannerTimer);
                    });
            }
        },
        // legacyGetUserMediaSupport() : Promise<any> {
        //     return (constraints) => {
        //         // First get ahold of the legacy getUserMedia, if present
        //         const { getUserMedia } = navigator;
        //         // Some browsers just don't implement it - return a rejected promise with an error
        //         // to keep a consistent interface
        //         if (!getUserMedia) {
        //             return Promise.reject(
        //                 new Error('getUserMedia is not implemented in this browser'),
        //             );
        //         }
        //         // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        //         return new Promise(((resolve, reject) => {
        //             getUserMedia.call(navigator, constraints, resolve, reject);
        //         }));
        //     };
        // },
        /**
     * setup media
     */
        setupMedia() : void {
            // if (navigator.mediaDevices.getUserMedia === undefined) {
            //     navigator.mediaDevices.getUserMedia = this.legacyGetUserMediaSupport();
            // }

            this.testMediaAccess();
        },
        /**
     * load available cameras
     */
        loadCameras() : void{
            navigator.mediaDevices
                .enumerateDevices()
                .then((deviceInfos: any) => {
                    for (let i = 0; i !== deviceInfos.length; i += 1) {
                        const deviceInfo = deviceInfos[i];
                        if (deviceInfo.kind === 'videoinput') {
                            this.cameras.push(deviceInfo);
                        }
                    }
                })
                .then(() => {
                    if (!this.camerasListEmitted) {
                        if (this.selectFirstDevice && this.cameras.length > 0) {
                            this.deviceId = this.cameras[0].deviceId;
                        }
                        this.$emit('cameras', this.cameras);
                        this.camerasListEmitted = true;
                    }
                })
                .catch((error) => this.$emit('notsupported', error));
        },
        /**
     * change to a different camera stream, like front and back camera on phones
     */
        changeCamera(deviceId : any) : void{
            this.stop();
            this.$emit('camera-change', deviceId);
            this.loadCamera(deviceId);
        },
        /**
     * load the stream to the
     */
        loadSrcStream(stream: any) : void{
            const el: HTMLVideoElement = this.$refs.video as HTMLVideoElement;

            if ('srcObject' in el) {
                // new browsers api
                el.srcObject = stream;
            }
            // Emit video start/live event
            el.onloadedmetadata = () => {
                this.$emit('video-live', stream);
                if (this.scanner) this.prepareScanner();
                this.isLoading = false;
            };
            this.$emit('started', stream);
        },
        /**
     * stop the selected streamed video to change camera
     */
        stopStreamedVideo(videoElem: HTMLVideoElement) : void{
            const stream: any = videoElem.srcObject;
            const tracks: any = stream.getTracks();
            tracks.forEach((track: any) => {
                // stops the video track
                track.stop();
                this.$emit('stopped', stream);
                const el: HTMLVideoElement = this.$refs.video as HTMLVideoElement;
                el.srcObject = null;
                this.source = null;
            });
        },
        // stop the video
        stop() : void{
            clearTimeout(this.scannerTimer);
            const el: HTMLVideoElement = this.$refs.video as HTMLVideoElement;
            if (this.$refs.video !== null && el.srcObject) {
                this.stopStreamedVideo(el);
            }
        },
        // start the video
        start() : void{
            if (this.deviceId) {
                this.loadCamera(this.deviceId);
            }
        },
        // pause the video
        pause() : void{
            const el: HTMLVideoElement = this.$refs.video as HTMLVideoElement;
            if (el !== null && el.srcObject) {
                el.pause();
            }
        },
        // resume the video
        resume() : void{
            const el: HTMLVideoElement = this.$refs.video as HTMLVideoElement;
            if (el !== null && el.srcObject) {
                el.play();
            }
        },

        testMediaAccess() : void{
            const constraints: any = { video: true };
            if (this.resolution) {
                constraints.video = {};
                constraints.video.height = this.resolution.height;
                constraints.video.width = this.resolution.width;
            }
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    // Make sure to stop this MediaStream
                    const tracks = stream.getTracks();
                    tracks.forEach((track) => {
                        track.stop();
                    });
                    this.loadCameras();
                })
                .catch((error) => this.$emit('error', error));
        },
        /**
     * load the camera passed as index!
     */
        loadCamera(device: any) : void{
            const constraints: any = { video: { deviceId: { exact: device } } };
            if (this.resolution) {
                constraints.video.height = this.resolution.height;
                constraints.video.width = this.resolution.width;
            }
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => this.loadSrcStream(stream))
                .catch((error) => this.$emit('error', error));
        },
        /**
     * capture screenshot
     */
        capture() : string {
            return this.getCanvas().toDataURL(this.screenshotFormat);
        },
        /**
     * get canvas
     */
        getCanvas() : HTMLCanvasElement {
            const el: HTMLVideoElement = this.$refs.video as HTMLVideoElement;

            if (!this.ctx) {
                const canvas = document.createElement('canvas');
                canvas.height = el.videoHeight;
                canvas.width = el.videoWidth;
                this.canvas = canvas;
                this.ctx = canvas.getContext('2d');
            }
            const { ctx, canvas } = this;
            ctx.drawImage(el, 0, 0, canvas.width, canvas.height);
            return canvas;
        },
    },
});

export default component;
