



















import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        name: {
            type: String,
            default: null,
        },
        tag: {
            type: String,
            default: 'div',
        },

    },
    computed:
    {
        permissions() : Array<string> {
            return this.$store.getters.permissions;
        },
        hasAccess() : Boolean {
            if (this.permissions) {
                return this.permissions.includes(this.name);
            }
            return false;
        },
    },

});

export default component;
