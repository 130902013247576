

































































































































































































































































































































































































































































import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        opened: {
            type: Boolean,
            default: true,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            notes: [] as Array<any>,
            newNote: '',
        };
    },
    watch: {
        $route() {
            this.$emit('close-sidebar');
        },
    },

    mounted() {
        this.getNotes();
    },
    methods: {
        saveNotes(): void {
            localStorage.setItem('notes', JSON.stringify(this.notes));
        },
        addNote(): void {
            if (this.newNote) {
                this.notes.push(this.newNote);
                this.newNote = '';
                this.saveNotes();
            }
        },
        removeNote(index: number): void {
            this.$delete(this.notes, index);
            this.saveNotes();
        },
        getNotes(): void {
            const notes = localStorage.getItem('notes');
            if (notes) {
                this.notes = JSON.parse(notes);
            }
        },
    },

});

export default component;
