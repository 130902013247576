





























































































































































import { mapGetters } from 'vuex';
import Base from '@/mixins/Base.vue';
import { INotificationsResponse } from '@/interfaces/notification';

import i18n from '../../i18n';

const component = Base.extend({
    computed: {
        ...mapGetters(['languages', 'role', 'notifications']),
        lang(): any {
            if (this.languages) {
                return this.languages[0];
            }
            return undefined;
        },
    },
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            i18n,
        };
    },

    mounted() {
        this.getNotifications();
    },

    methods: {

        listenInserts() : void {
            this.$supabase.from(`notifications:user_id=eq.${this.currentUser.id}`).on('INSERT', (data) => {
                if (data && data.new) {
                    this.$emit('info', {
                        title: data.new.title,
                        message: data.new.message,
                        duration: 5000,
                    });
                }
                this.getNotifications();
            }).subscribe();
        },
        getNotifications() : void {
            this.get<INotificationsResponse>('notifications')
                .then(({ data }) => {
                    this.$store.dispatch('getNotifications', data.data);
                    this.listenInserts();
                });
        },
    },

});
export default component;
