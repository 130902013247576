import { extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

// eslint-disable-next-line no-restricted-syntax
for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, {
        ...validation,
    });
}

localize({
    en: {
        messages: {
            required: 'The field is required',
            required_if: 'The field is required',
            min: 'The field must have no less than {length} characters',
            max: 'The field must have no more than {length} characters',
        },
    },
    fr: {
        messages: {
            required: 'Ce champs est requis',
            required_if: 'Ce champs est requis',
            min: 'The field must have no less than {length} characters',
            max: 'The field must have no more than {length} characters',
        },
    },
});

extend('vat', {
    message: (field: any) => `The ${field} value is not in a valid format.`,
    validate: (value: any) => new RegExp(/^((NL)?[0-9]{9}B[0-9]{2}|(FR)?[0-9A-Z]{2}[0-9]{9}|(LU)?[0-9]{8}|(BE)?0[0-9]{9})$/).test(value.toUpperCase()),
});

extend('nullable', {

    validate: (value: any) => true,
});
