import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Loader } from '@googlemaps/js-api-loader';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import Editor from '@tinymce/tinymce-vue';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Buefy from 'buefy';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
import PortalVue from 'portal-vue';
import QrcodeVue from 'qrcode.vue';
import VueAnalytics from 'vue-analytics';

// @ts-ignore
import VueHotkey from 'v-hotkey';
import { localize, ValidationObserver, ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
// @ts-ignore
import Avatar from 'vue-avatar';
import VueAxios from 'vue-axios';
// @ts-ignore
import VueMoment from 'vue-moment';
// @ts-ignore
import VuePerfectScrollBar from 'vue-perfect-scrollbar';
import VueSupabase from 'vue-supabase';
import draggable from 'vuedraggable';
import App from './App.vue';
import Address from './components/Address.vue';
import AddressForm from './components/AddressForm.vue';
import BlogCategory from './components/BlogCategory.vue';
import BuildingType from './components/BuildingType.vue';
import Client from './components/Client.vue';
import ClientType from './components/ClientType.vue';
import Color from './components/Color.vue';
import Country from './components/Country.vue';
import Flow from './components/Flow.vue';
import HasPermission from './components/HasPermission.vue';
import Icon from './components/Icon.vue';
import Language from './components/Language.vue';
import Link from './components/Link.vue';
import Object from './components/Object.vue';
import ObjectCategory from './components/ObjectCategory.vue';
import Permission from './components/Permission.vue';
import Project from './components/Project.vue';
import Region from './components/Region.vue';
import Role from './components/Role.vue';
import Service from './components/Service.vue';
import ServiceCategory from './components/ServiceCategory.vue';
import Source from './components/Source.vue';
import Tags from './components/Tags.vue';
import User from './components/User.vue';
import UserType from './components/UserType.vue';
import Webcam from './components/Webcam.vue';
import i18n from './i18n';
import { IAddress } from './interfaces/address';
import router from './router';
import store from './store';
import './validate';

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_URL,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ['localhost', 'spoq.digital', /^\//],
        }),
    ],
    tracesSampleRate: 1.0,
});

Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    router,
});

Vue.use(VueSupabase, {
    supabaseUrl: process.env.VUE_APP_SUPABASE_URL,
    supabaseKey: process.env.VUE_APP_SUPABASE_KEY,
    supabaseOptions: {},
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

Vue.component('lottie-animation', LottieAnimation);

Vue.component('sp-blog-category', BlogCategory);
Vue.component('sp-address', Address);
Vue.component('sp-address-form', AddressForm);
Vue.component('sp-tags', Tags);
Vue.component('sp-role', Role);
Vue.component('sp-client', Client);
Vue.component('sp-client-type', ClientType);
Vue.component('sp-language', Language);
Vue.component('sp-country', Country);
Vue.component('sp-region', Region);
Vue.component('sp-service', Service);
Vue.component('sp-project', Project);
Vue.component('sp-flow', Flow);
Vue.component('sp-webcam', Webcam);
Vue.component('sp-object-category', ObjectCategory);
Vue.component('sp-service-category', ServiceCategory);
Vue.component('sp-object', Object);
Vue.component('sp-building-type', BuildingType);
Vue.component('sp-source', Source);
Vue.component('sp-user', User);
Vue.component('sp-user-type', UserType);
Vue.component('sp-link', Link);
Vue.component('sp-permission', Permission);
Vue.component('sp-has-permission', HasPermission);
Vue.component('sp-icon', Icon);
Vue.component('sp-color', Color);
Vue.component('editor', Editor);
Vue.component('qrcode-vue', QrcodeVue);

Vue.component('draggable', draggable);

Vue.use(PortalVue);

Vue.use(VueMoment);

Vue.use(VueAxios, axios);

Vue.filter('truncate', (text: String, stop: number): String => {
    if (text) return text.slice(0, stop) + (stop < text.length ? '...' : '');
    return '';
});

Vue.filter('address', (address: IAddress): String => `${address.street} ${address.number}, ${address.postal_code} ${address.city} - ${address.country?.name}`);

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('avatar', Avatar);
Vue.use(VueHotkey);
Vue.use(VuePerfectScrollBar);
Vue.component('VuePerfectScrollBar', VuePerfectScrollBar);

Vue.use(Buefy, {
    defaultIconPack: 'fas',
    defaultIconComponent: 'font-awesome-icon',
    defaultInputAutocomplete: false,
    defaultTabsAnimated: false,
    defaultFirstDayOfWeek: 1,
    // defaultTabsType: 'is-boxed',
    defaultTabsExpanded: false,
    defaultTooltipType: 'is-dark',
});

axios.defaults.baseURL = process.env.VUE_APP_API;
axios.interceptors.request.use((c) => {
    const config = c;
    const { token } = Vue.prototype.$keycloak;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = 'application/json';
    config.headers.Locale = i18n.locale;

    return config;
});

axios.interceptors.request.use((c: AxiosRequestConfig) => {
    const config:AxiosRequestConfig = c;
    if (config.method === 'get' && config.params) {
        window.Object.keys(config.params).forEach((param: any) => {
            if (typeof config.params[param] === 'boolean') {
                config.params[param] = config.params[param] ? 1 : 0;
            }
        });
    }

    if (config.method === 'get' && config.params && config.params.sortBy && config.params.orderBy) {
        config.params.sort = `${config.params.sortBy}:${config.params.orderBy}`;
        config.params.per_page = config.params.per_page || 20;
        delete config.params.sortBy;
        delete config.params.orderBy;
    }

    return config;
}, (err) => {
    Promise.reject(err);
});

axios.interceptors.response.use((response: AxiosResponse) => {
    if (response.status >= 400) {
        return Promise.reject(response);
    }
    return Promise.resolve(response);
}, (error: AxiosError) => Promise.reject(error));

const MAX_REQUESTS_COUNT = 3;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;
/**
 * Axios Request Interceptor
 */
axios.interceptors.request.use((config) => new Promise((resolve, reject) => {
    const interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
            PENDING_REQUESTS += 1;
            clearInterval(interval);
            resolve(config);
        }
    }, INTERVAL_MS);
}));

axios.interceptors.response.use((response) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
}, (error) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
});

const loader = new Loader({
    apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY as string,
    version: 'weekly',
    libraries: ['places'],
});
loader.load();

Vue.directive('permission', {
    bind(el, binding, vnode) {
        const isHidden = store.state.permissions.indexOf(binding.value) === -1;
        if (isHidden) {
            if (vnode.componentInstance) {
                vnode.componentInstance.$destroy();
            }

            if (el.parentNode) {
                el.parentNode.removeChild(el);
            }
        }
    },
});

Vue.config.silent = false;
Vue.config.productionTip = false;

Vue.use(VueKeyCloak, {
    init: {
        onLoad: 'login-required',
        checkLoginIframe: false,
    },
    logout: {
        redirectUri: 'http://localhost:4000',
    },
    config: {
        realm: process.env.VUE_APP_KEYCLOAK_REALM,
        url: process.env.VUE_APP_KEYCLOAK_BASE_URL,
        clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
        secret: process.env.VUE_APP_KEYCLOAK_CLIENT_SECRET,
    },
    onInitError: (err: any) => {

    },
    onReady: (kc: unknown) => {
        new Vue({
            store,
            router,
            i18n,
            render: (h) => h(App),
            watch: {
                '$i18n.locale':
                {
                    handler(value) {
                        localize(value);
                    },
                },
            },
        }).$mount('#app');
    },

});
