
















































































































import { debounce } from 'ts-debounce';

import Base from '@/mixins/Base.vue';
import { IAddress, IAddressesResponse } from '@/interfaces/address';

const component = Base.extend({
    props: {
        value: {
            type: Object,
            default: null,
        },
        clientId: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        withType: {
            type: Boolean,
            default: true,
        },
        allowNew: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            newAddress: {} as any | IAddress,
            isEditAddressModalActive: false,
            name: '',
            page: 1,
            totalPages: 1,
            isLoading: false,
            newValue: this.value ? this.value : {},
            data: [] as Array<IAddress>,
        };
    },
    mounted() {
        if (!this.value) this.$emit('input', null);
    },
    methods: {
        createAddress() {
            this.newAddress.address = `${this.newAddress.street || ''} ${this.newAddress.number || ''} ${this.newAddress.box || ''}, ${this.newAddress.postal_code || ''} ${this.newAddress.city || ''}`;
            this.newValue = this.newAddress;
            this.$emit('input', this.newValue);
            this.isEditAddressModalActive = false;
            this.newAddress = {};
        },
        reset(): void {
            this.page = 1;
            this.totalPages = 1;
            this.name = '';
            this.data = [] as Array<IAddress>;
            this.fetchData();
        },
        onFocus(): void {
            this.reset();
        },
        onSelect(obj: IAddress): void {
            if (!obj) {
                this.reset();
            }
            this.$emit('input', obj || null);
        },
        // eslint-disable-next-line func-names
        onTyping: debounce(function (this: any, name: string = ''): void {
            this.fetchData(name);
        }, 500),

        fetchData(name: string = '') {
            if (this.name !== name) {
                this.name = name;
                this.page = 1;
                this.totalPages = 1;
                this.data = [];
            }

            if (this.page > this.totalPages) {
                return;
            }

            this.get<IAddressesResponse>(`/clients/${this.clientId}/addresses`, {
                params: {
                    name: this.name, type: this.type, page: this.page, per_page: 20,
                },
            })
                .then(({ data }) => {
                    this.data.push(...data.data);
                    this.page += 1;
                });
        },
    },
    watch: {
        value(value): void {
            this.newValue = value || {};
        },
    },
});

export default component;
