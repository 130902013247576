
































import { debounce } from 'ts-debounce';

import Base from '@/mixins/Base.vue';
import { IRole, IRolesResponse } from '@/interfaces/role';

const component = Base.extend({
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            name: '',
            page: 1,
            totalPages: 1,
            isLoading: false,
            newValue: this.value ? this.value.name : '',
            data: [] as Array<IRole>,
        };
    },
    mounted() {
        if (!this.value) this.$emit('input', null);
    },
    methods: {
        reset(): void {
            this.page = 1;
            this.totalPages = 1;
            this.name = '';
            this.data = [];
            this.fetchData();
        },
        onFocus(): void {
            this.reset();
        },
        onSelect(obj: IRole): void {
            if (!obj) this.reset();
            this.$emit('input', obj || null);
        },
        // eslint-disable-next-line func-names
        onTyping: debounce(function (this: any, name: string = ''): void {
            this.fetchData(name);
        }, 500),

        fetchData(name: string = '') {
            if (this.name !== name) {
                this.name = name;
                this.page = 1;
                this.totalPages = 1;
                this.data = [];
            }

            if (this.page > this.totalPages) {
                return;
            }

            this.get<IRolesResponse>('roles', { params: { name: this.name, page: this.page, per_page: 20 } })
                .then(({ data }) => {
                    this.data.push(...data.data);
                    this.page += 1;

                    this.totalPages = data.meta.last_page;
                });
        },
        // eslint-disable-next-line func-names
        fetchMoreData: debounce(function (this: any, name: string): void {
            this.fetchData(name);
        }, 300),

    },
    watch: {
        value(value): void {
            this.newValue = value ? value.name : '';
        },
    },
});

export default component;
