






























































import { debounce } from 'ts-debounce';

import Base from '@/mixins/Base.vue';
import { IServiceCategory, IServiceCategoriesResponse } from '@/interfaces/servicecategory';

const component = Base.extend({
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Object, Array],
            default: null,
        },

        withClient: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            name: '',
            page: 1,
            totalPages: 1,
            isLoading: false,
            newValue: null,
            data: [] as Array<IServiceCategory>,
        };
    },
    mounted() {
        if (!this.multiple) this.newValue = this.value ? this.value.name : '';
        else this.newValue = this.value ? this.value : [];

        if (!this.value) this.$emit('input', this.multiple ? [] : null);
    },
    methods: {
        reset(): void {
            this.page = 1;
            this.totalPages = 1;
            this.name = '';
            this.data = [];
            this.fetchData();
        },
        onBeforeAdding(value: any): boolean {
            if (this.multiple && this.newValue && (this.newValue as any).findIndex((v: any) => v.id === value.id) !== -1) {
                return false;
            }
            return true;
        },
        onFocus(): void {
            this.reset();
        },
        onSelect(obj: IServiceCategory): void {
            if (!obj) this.reset();
            if (!this.multiple) this.$emit('input', obj || null);
        },
        onInput(obj: IServiceCategory): void {
            if (this.multiple) this.$emit('input', obj || []);
        },
        // eslint-disable-next-line func-names
        onTyping: debounce(function (this: any, name: string = ''): void {
            this.fetchData(name);
        }, 500),

        fetchData(name: string = '') {
            if (this.name !== name) {
                this.name = name;
                this.page = 1;
                this.totalPages = 1;
                this.data = [];
            }

            if (this.page > this.totalPages) {
                return;
            }

            this.get<IServiceCategoriesResponse>('service-categories', {
                params: {
                    name: this.name,
                    page: this.page,
                    client_id: this.clientId,

                },
            }).then(({ data }) => {
                this.data.push(...data.data);
                this.page += 1;

                this.totalPages = data.meta.last_page;
            });
        },
        // eslint-disable-next-line func-names
        fetchMoreData: debounce(function (this: any, name: string): void {
            this.fetchData(name);
        }, 300),

    },
    watch: {
        value(value): void {
            if (this.multiple) this.newValue = value || [];
            else this.newValue = value ? value.name : '';
        },
        clientId(): void {
            this.reset();
        },
    },
});

export default component;
