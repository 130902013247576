import Vue from 'vue';
import Vuex from 'vuex';
import { ILanguage } from './interfaces/language';
import { INotification } from './interfaces/notification';
import { IPermission } from './interfaces/permission';
import { IRole } from './interfaces/role';
import { IUser } from './interfaces/user';

Vue.use(Vuex);

interface IState {
    role: IRole | any,
    permissions: Array<IPermission>,
    user: IUser | any,
    languages: Array<ILanguage>,
    notifications: Array<INotification>,
}

const state: IState = {
    languages: [],
    role: {},
    permissions: [],
    user: undefined,
    notifications: [],
} as IState;

const mutations = {
    CHANGE_COMPANY(state: any, id: any) {
        state.clientId = id;
    },
    GET_USER(state: any, data: any) {
        state.user = data;
        if (data.role) state.role = data.role;
        if (data.permissions) state.permissions = data.permissions;
    },
    GET_LANGUAGES(state: any, data: any) {
        state.languages = data;
    },
    GET_NOTIFICATIONS(state: any, data: any) {
        state.notifications = data;
    },
    UPDATE_ROLE(state: any, role: any) {
        state.role.name = role.name;
    },
};

const actions = {
    getUser(context: any, data: IUser) {
        context.commit('GET_USER', data);
    },

    updateRole(context: any, role: IRole) {
        context.commit('UPDATE_ROLE', role);
    },
    changeClient(context: any, id: number) {
        context.commit('CHANGE_COMPANY', id);
    },
    getLanguages(context: any, data: Array<ILanguage>) {
        context.commit('GET_LANGUAGES', data);
    },
    getNotifications(context: any, data: Array<INotification>) {
        context.commit('GET_NOTIFICATIONS', data);
    },
};

const getters = {

    role(state: IState) : IRole {
        return state.role;
    },
    permissions(state: IState) : Array<IPermission> {
        return state.permissions;
    },
    user(state: IState) : IUser {
        return state.user;
    },
    notifications(state: IState) : Array<INotification> {
        return state.notifications;
    },
    languages(state: IState): Array<ILanguage> {
        return state.languages;
    },
};
const store = new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
});

export default store;
