import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from './store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [

    {
        path: '/', name: 'dashboard', component: () => import('./pages/Dashboard.vue'),
    },
    {
        path: '/profile', name: 'profile', component: () => import('./pages/Profile.vue'),
    },

    // Services
    {
        meta: { permission: 'services.read' },
        path: '/services',
        name: 'services.list',
        component: () => import('./pages/services/List.vue'),
        children: [
            {
                meta: { permission: 'services.create' }, path: 'create/:parent_id?/:client_id?', name: 'services.create', component: () => import('./pages/services/Create.vue'),
            },
            {
                meta: { permission: 'services.delete' }, path: '/services/:id/delete', name: 'services.delete', component: () => import('./pages/services/Delete.vue'),
            },
        ],
    },

    {
        meta: { permission: 'services.read' }, path: '/services/:id/:client_id?', name: 'services.edit', component: () => import('./pages/services/Edit.vue'),
    },

    // Users
    {
        meta: { permission: 'users.read' },
        path: '/users',
        name: 'users.list',
        component: () => import('./pages/users/List.vue'),
        children: [

            {
                meta: { permission: 'users.create' }, path: 'create', name: 'users.create', component: () => import('./pages/users/Create.vue'),
            },
            {
                meta: { permission: 'users.delete' }, path: '/users/:id/delete', name: 'users.delete', component: () => import('./pages/users/Delete.vue'),
            },
        ],
    },
    {
        meta: { permission: 'users.read' }, path: '/users/:id', name: 'users.edit', component: () => import('./pages/users/Edit.vue'),
    },

    // Jobs
    {
        meta: { permission: 'jobs.read' },
        path: '/jobs',
        name: 'jobs.list',
        component: () => import('./pages/jobs/List.vue'),
        children: [

            {
                meta: { permission: 'jobs.create' }, path: 'create/:client_id?/:service_id?', name: 'jobs.create', component: () => import('./pages/jobs/Create.vue'),
            },
        ],
    },
    {
        meta: { permission: 'jobs.read' }, path: '/jobs/:id', name: 'jobs.edit', component: () => import('./pages/jobs/Edit.vue'),
    },
    // Categories
    {
        meta: { permission: 'objects.categories.read' },
        path: '/object-categories',
        name: 'categories.list',
        component: () => import('./pages/objectcategories/List.vue'),
        children: [

            {
                meta: { permission: 'objects.categories.create' }, path: 'create', name: 'categories.create', component: () => import('./pages/objectcategories/Create.vue'),
            },
            {
                meta: { permission: 'objects.categories.delete' }, path: ':id/delete', name: 'categories.delete', component: () => import('./pages/objectcategories/Delete.vue'),
            },
        ],
    },

    {
        meta: { permission: 'objects.categories.read' }, path: '/object-categories/:id', name: 'categories.edit', component: () => import('./pages/objectcategories/Edit.vue'),
    },

    // Flows
    {
        meta: { permission: 'flows.read' },
        path: '/flows',
        name: 'flows.list',
        component: () => import('./pages/flows/List.vue'),
        children: [

            {
                meta: { permission: 'flows.create' }, path: 'create', name: 'flows.create', component: () => import('./pages/flows/Create.vue'),
            },
            {
                meta: { permission: 'flows.delete' }, path: ':id/delete', name: 'flows.delete', component: () => import('./pages/flows/Delete.vue'),
            },
        ],
    },

    {
        meta: { permission: 'flows.read' }, path: '/flows/:id', name: 'flows.edit', component: () => import('./pages/flows/Edit.vue'),
    },

    // Infringements
    {
        meta: { permission: 'infringements.read' },
        path: '/infringements',
        name: 'infringements.list',
        component: () => import('./pages/infringements/List.vue'),
        children: [

            {
                meta: { permission: 'infringements.create' }, path: 'create', name: 'infringements.create', component: () => import('./pages/infringements/Create.vue'),
            },
            {
                meta: { permission: 'infringements.delete' }, path: ':id/delete', name: 'infringements.delete', component: () => import('./pages/infringements/Delete.vue'),
            },
        ],
    },

    {
        meta: { permission: 'infringements.read' }, path: '/infringements/:id', name: 'infringements.edit', component: () => import('./pages/infringements/Edit.vue'),
    },

    // Objects
    {
        meta: { permission: 'objects.read' },
        path: '/objects',
        name: 'objects.list',
        component: () => import('./pages/objects/List.vue'),
        children: [

            {
                meta: { permission: 'objects.create' }, path: 'create', name: 'objects.create', component: () => import('./pages/objects/Create.vue'),
            },
            {
                meta: { permission: 'objects.delete' }, path: ':id/delete', name: 'objects.delete', component: () => import('./pages/objects/Delete.vue'),
            },
        ],
    },

    {
        meta: { permission: 'objects.read' }, path: '/objects/:id', name: 'objects.edit', component: () => import('./pages/objects/Edit.vue'),
    },

    {
        meta: { permission: 'objects.scanner.read' }, path: '/objects/scanner', name: 'objects.scanner', component: () => import('./pages/objects/Scanner.vue'),
    },

    // blog

    {
        meta: { permission: 'blog.posts.read' },
        path: '/blog/posts',
        name: 'blog.posts.list',
        component: () => import('./pages/blog/posts/List.vue'),
        children: [

            {
                meta: { permission: 'blog.posts.create' }, path: 'create', name: 'blog.posts.create', component: () => import('./pages/blog/posts/Create.vue'),
            },
            {
                meta: { permission: 'blog.posts.delete' }, path: ':id/delete', name: 'blog.posts.delete', component: () => import('./pages/blog/posts/Delete.vue'),
            },
        ],
    },
    {
        meta: { permission: 'blog.posts.read' }, path: '/blog/posts/all', name: 'blog.posts.all', component: () => import('./pages/blog/posts/Wall.vue'),
    },
    {
        meta: { permission: 'blog.posts.read' }, path: '/blog/posts/:id', name: 'blog.posts.edit', component: () => import('./pages/blog/posts/Edit.vue'),
    },
    {
        meta: { permission: 'blog.posts.read' }, path: '/blog/posts/:id/:slug', name: 'blog.posts.view', component: () => import('./pages/blog/posts/View.vue'),
    },
    // projects

    {
        meta: { permission: 'projects.read' },
        path: '/projects',
        name: 'projects.list',
        component: () => import('./pages/projects/List.vue'),
        children: [

            {
                meta: { permission: 'projects.create' }, path: 'create', name: 'projects.create', component: () => import('./pages/projects/Create.vue'),
            },
            {
                meta: { permission: 'projects.delete' }, path: ':id/delete', name: 'projects.delete', component: () => import('./pages/projects/Delete.vue'),
            },
        ],
    },

    {
        meta: { permission: 'projects.read' }, path: '/projects/:id', name: 'projects.edit', component: () => import('./pages/projects/Edit.vue'),
    },

    // surveys

    {
        meta: { permission: 'surveys.read' },
        path: '/surveys',
        name: 'surveys.list',
        component: () => import('./pages/surveys/List.vue'),
        children: [

            {
                meta: { permission: 'surveys.create' }, path: 'create', name: 'surveys.create', component: () => import('./pages/surveys/Create.vue'),
            },
            {
                meta: { permission: 'surveys.delete' }, path: ':id/delete', name: 'surveys.delete', component: () => import('./pages/surveys/Delete.vue'),
            },
        ],
    },

    {
        meta: { permission: 'surveys.read' }, path: '/surveys/:id', name: 'surveys.edit', component: () => import('./pages/surveys/Edit.vue'),
    },

    // roles
    {
        meta: { permission: 'roles.read' },
        path: '/roles',
        name: 'roles.list',
        component: () => import('./pages/roles/List.vue'),
        children: [

            {
                meta: { permission: 'roles.create' }, path: 'create', name: 'roles.create', component: () => import('./pages/roles/Create.vue'),
            },
            {
                meta: { permission: 'roles.delete' }, path: ':id/delete', name: 'roles.delete', component: () => import('./pages/roles/Delete.vue'),
            },
        ],
    },

    {
        meta: { permission: 'roles.read' }, path: '/roles/:id', name: 'roles.edit', component: () => import('./pages/roles/Edit.vue'),
    },

    // Clients
    {
        meta: { permission: 'clients.read' },
        path: '/clients',
        name: 'clients.list',
        component: () => import('./pages/clients/List.vue'),
        children: [

            {
                meta: { permission: 'clients.create' }, path: 'create', name: 'clients.create', component: () => import('./pages/clients/Create.vue'),
            },
            {
                meta: { permission: 'clients.delete' }, path: '/clients/:id/delete', name: 'clients.delete', component: () => import('./pages/clients/Delete.vue'),
            },
        ],
    },

    {
        meta: { permission: 'clients.read' }, path: '/clients/:id', name: 'clients.edit', component: () => import('./pages/clients/Edit.vue'),
    },
    // Tips
    {
        meta: { permission: 'tips.read' },
        path: '/tips',
        name: 'tips.list',
        component: () => import('./pages/tips/List.vue'),
        children: [

            {
                meta: { permission: 'tips.create' }, path: 'create', name: 'tips.create', component: () => import('./pages/tips/Create.vue'),
            },
            {
                meta: { permission: 'tips.delete' }, path: '/tips/:id/delete', name: 'tips.delete', component: () => import('./pages/tips/Delete.vue'),
            },
        ],
    },

    {
        meta: { permission: 'tips.read' }, path: '/tips/:id', name: 'tips.edit', component: () => import('./pages/tips/Edit.vue'),
    },
    // Sales
    {
        meta: { permission: 'sales.read' },
        path: '/sales',
        name: 'sales.list',
        component: () => import('./pages/sales/List.vue'),
    },
    // Notifications
    {
        meta: { permission: 'notifications.read' },
        path: '/notifications',
        name: 'notifications.list',
        component: () => import('./pages/notifications/List.vue'),
        children: [
            {
                meta: { permission: 'notifications.read' }, path: '/notifications/:id', name: 'notifications.view', component: () => import('./pages/notifications/View.vue'),
            },
        ],
    },
    // Logs
    {
        meta: { permission: 'logs.read' },
        path: '/logs',
        name: 'logs.list',
        component: () => import('./pages/logs/List.vue'),
    },

];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to: any, from: any, next: Function) => {
    if (!to.meta.middleware) {
        return next();
    }
    const { middleware } = to.meta;

    const context = {
        to,
        from,
        next,
        store,
    };
    return middleware[0]({
        ...context,
    });
});

export default router;
